import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const FullWrapper = styled.div`
  color: #fff !important;
  * {
    color: #fff !important;
  }
  .cog-abuse, .cog-branding {
    display: none !important;
  }
  .cog-button__text {
    color: #555 !important;
  }
  .cog-button--submit span {
    color: #fff !important;
  }
  form {
    input, textarea {
      background-color: transparent !important;
    }
  }
`

const Wrapper = styled.div`
  .c-forms-heading {
    display: none;
  }
  *,
  .c-field.c-required .c-label:after {
    color: #fff !important;
  }
  .c-field select {
    background-color: transparent;
  }
  button {
    line-height: 1em;
  }
  legend {
    vertical-align: baseline;
    font-size: 0.8rem;
    line-height: 1.5;
    margin: 0 0 1rem 0;
    text-transform: uppercase;
    box-sizing: border-box;
    color: #fff !important;
    display: inline;
    cursor: pointer;
  }
  form ~ div {
    visibility: hidden;
  }
  .c-fileupload .c-upload-button {
    padding: 0;
    padding-right: 5px;
  }
  label,
  legend {
    letter-spacing: 0.02em;
    text-transform: capitalize;
  }
  form {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  * {
    margin-bottom: 16px;
    text-decoration: none;
    border-bottom: none;
  }
  @media only screen and (max-width: 500px) {
    justify-content: center;
    > * {
      width: 80%;
      display: block;
      margin-bottom: 4px;
      * {
        width: 100%;
      }
    }
  }
`

const JDs = [
  {
    link: 'Kitchen_Lead',
    title: 'Kitchen Lead',
  },
  {
    link: 'Counter_Clerk',
    title: 'Counter Clerk',
  },
  {
    link: 'Line_Cook',
    title: 'Line Cook',
  },
  {
    link: 'Market_Maven',
    title: 'Market Maven',
  },
  {
    link: 'Food_Expo',
    title: 'Food Expeditor',
  },
]

const Form = () => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://www.cognitoforms.com/s/EudEwtQcXU-lfOkvlbrX5g'
    script.async = true

    document.body.appendChild(script)

    script.onload = () => {
      // this actually loads the script, not sure why:
      console.log(window?.Cognito?.load('forms', { id: '1' }));
      setLoaded(true);
    }
  }, [])

  return (
    <FullWrapper>
      <h2>Descriptions</h2>
      <LinkWrapper>
        {JDs.map(jd => {
          return (
            <a
              key={jd.link}
              href={`/Hopper JD_${jd.link}.pdf`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <button>{jd.title}</button>
            </a>
          )
        })}
      </LinkWrapper>
      <Wrapper className="cognito" />
    </FullWrapper>
  )
}

export default Form
