import React from 'react'

const Map = () => {
  return (
    <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          width="600"
          height="400"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=40%20n%20last%20chance&t=&z=19&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          style={{
            maxWidth: '100%',
          }}
        ></iframe>
      </div>
    </div>
  )
}

export default Map
