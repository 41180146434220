import PropTypes from 'prop-types'
import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Apply from "./ApplicationForm"
import Map from "./Map"

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const data = node.data.target
      return <div
      style={{marginBottom: 30}}
      >
      <img style={{
        display: 'block',
        margin: '0 auto',
        maxWidth: '100%',

      }} src={data.file.url} />
      {data.description && <p
      style={{textAlign: "center",
      backgroundColor: "rgba(0,0,0,.5)",
      padding: 10,
      fontSize: '.75em'
      }}
      >{data.description}</p>}
      </div>
    },
  }
}
class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        className="scoop"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <StaticQuery
          query={graphql`
              {
                allContentfulPage {
                  edges {
                    node {
                      pageTitle
                      pageContent {
                        raw
                        references {
                          __typename
                          contentful_id
                          file {
                            url
                          }
                          description
                        }
                      }
                    }
                  }
                }
              }
          `}
          render={data => {
            const {allContentfulPage: {edges: pages}} = data;
            return pages.map(page => page.node).map((page, index) => {
              return (<article
                  key={page.pageTitle}
                  id={page.pageTitle}
                  className={`${
                    this.props.article === page.pageTitle ? 'active' : ''
                  } ${this.props.articleTimeout ? 'timeout' : ''}`}
                  style={{ display: 'none' }}
                >
                  {close}
                  <h2 className="major">{page.pageTitle}</h2>
                  {page.pageContent && renderRichText(page.pageContent, options)}
                  {page.pageTitle === 'APPLY' && <Apply />}
                  {page.pageTitle === 'LOCATION' && <Map />}
                </article>)}
            )
          }}
        />
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
