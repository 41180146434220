import PropTypes from 'prop-types'
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Header = props => {
  const {
    allContentfulPageList: {edges: queryPages},
    contentfulSiteSettings: {siteTitle, siteSubtitle, menuLinkText, menuLink, siteFavicon}
  } = useStaticQuery(graphql`
    query HeaderQuery {
      allContentfulPageList {
        edges {
          node {
            pages {
              pageTitle
              pageContent {
                raw
                references {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
      contentfulSiteSettings {
        siteTitle
        siteSubtitle
        menuLink
        menuLinkText
        siteFavicon {
          file {
            url
          }
        }
      }
    }
  `);

  let pages = queryPages[0].node.pages;

  return (
    <header id="header" style={props.timeout ? { display: 'none' } : {}}>
      <div className="logo">
        <img style={{ maxWidth: '75%' }} src={siteFavicon?.file?.url} />
      </div>
      <div className="content">
        <div className="inner">
          <h1 style={{ fontFamily: 'Gotham' }}>{siteTitle}</h1>
          <p>{siteSubtitle}</p>
            <div className="menu">
              <a href={menuLink} target="_blank" className="button">{menuLinkText}</a>
            </div>
        </div>
      </div>
      <nav>
        <ul>
          {pages.map(page => {
            return (
              <li key={page.pageTitle}>
                <button
                  onClick={() => {
                    props.onOpenArticle(page.pageTitle)
                  }}
                >
                  {page.pageTitle}
                </button>
              </li>
            )
          })}
        </ul>
      </nav>
    </header>
  )
}

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
