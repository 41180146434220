import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Hours = styled.div`
  p {
    margin-bottom: 0;
  }
`

const Footer = props => {
  const {
    contentfulSiteSettings: {siteTitle, hours}
  } = useStaticQuery(graphql`
    query FooterQuery {
       contentfulSiteSettings {
        siteTitle
        hours {
            raw
          }
       }
    }
  `)

  return (
    <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
      <p className="copyright" style={{marginBottom: 20}}>
        &copy; {siteTitle} {new Date().getFullYear()}
      </p>
      <Hours className="copyright">
      {renderRichText(hours)}
      </Hours>
    </footer>
  )
}

Footer.propTypes = {
  timeout: PropTypes.bool,
}

export default Footer
